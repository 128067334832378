import React from "react"
import ReactDOM from "react-dom"
import Flickity from "react-flickity-component"

import "../assets/css/flickity.css"

import realnet from "../images/logos/properties/realnet.png"
import seeff from "../images/logos/properties/seeff.png"
import centurypropertydevelopments from "../images/logos/properties/centurypropertydevelopments.png"
import tyson from "../images/logos/properties/tyson.png"

import remax from "../images/logos/properties/remax.png"
import chaseveritt from "../images/logos/properties/chaseveritt.png"
import realgrowth from "../images/logos/properties/realgrowth.png"
import century21 from "../images/logos/properties/century21.png"

import firzt from "../images/logos/properties/firzt.png"
import renprop from "../images/logos/properties/renprop.png"
import forge from "../images/logos/properties/forge.png"
import broll from "../images/logos/properties/broll.png"

import balwin from "../images/logos/properties/balwin.png"
import yourealty from "../images/logos/properties/yourealty.png"
import quorum from "../images/logos/properties/quorum.png"
import cosmopolitan from "../images/logos/properties/cosmopolitan.png"

import huizemark from "../images/logos/properties/huizemark.png"
import justproperty from "../images/logos/properties/justproperty.png"
import calgrom3 from "../images/logos/properties/calgrom3.png"
import jawitz from "../images/logos/properties/jawitz.png"

const flickityOptions = {
  autoPlay: 2000,
  prevNextButtons: false,
  pageDots: false,
}

const Logo = ({ image, url }) => {
  return (
    <div className="w-1/2 md:w-1/4">
      <div className="h-10 md:h-12 w-full flex mb-6">
        <img
          className="h-full w-auto mx-auto filter grayscale hover:grayscale-0"
          src={image}
          alt=""
        />
      </div>
    </div>
  )
}

function LandingTrustedBrands() {
  return (
    <Flickity className="w-full" options={flickityOptions}>
      <div className="w-full flex flex-wrap">
        <Logo image={realnet} />
        <Logo image={seeff} />
        <Logo image={centurypropertydevelopments} />
        <Logo image={tyson} />
      </div>

      <div className="w-full flex flex-wrap">
        <Logo image={remax} />
        <Logo image={chaseveritt} />
        <Logo image={realgrowth} />
        <Logo image={century21} />
      </div>

      <div className="w-full flex flex-wrap">
        <Logo image={firzt} />
        <Logo image={renprop} />
        <Logo image={forge} />
        <Logo image={broll} />
      </div>

      <div className="w-full flex flex-wrap">
        <Logo image={balwin} />
        <Logo image={yourealty} />
        <Logo image={quorum} />
        <Logo image={cosmopolitan} />
      </div>

      <div className="w-full flex flex-wrap">
        <Logo image={huizemark} />
        <Logo image={justproperty} />
        <Logo image={calgrom3} />
        <Logo image={jawitz} />
      </div>
    </Flickity>
  )
}

export default LandingTrustedBrands
